import axios from 'axios'

export class Comunicacao {
  static async obtemTodosMsgRecebidosNaoLidas (n) {
    return axios.get('/comunicacao/minhas/msg/recebidas/'+0+'?page='+n)
  }

  static async obtemTodosMsgRecebidosAbertas (n) {
    return axios.get('/comunicacao/minhas/msg/recebidas/'+1+'?page='+n)
  }

  static async obtemTodosMsgEnviadas (n) {
    return axios.get('/comunicacao/minhas/msg/enviadas?page='+n)
  }

  static async trocaStatusMensagem (id) {
    return axios.put('/comunicacao/msg/'+id+'/abirmensagem')
  }

  static async enviarMensagem (playload,tipo) {
    return axios.post('/comunicacao/msg/enviar/'+tipo,playload)
  }

}
